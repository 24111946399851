export default {
  Tier_0: {
    text: 'Tier 0',
    btnText: 'Verify Account',
    badgeClass: 'user-profile__avatar__badge--tier0'
  },
  Tier_1: {
    text: 'Tier 1',
    btnText: 'Continue verification',
    badgeClass: 'user-profile__avatar__badge--tier1'
  },
  Tier_2: {
    text: 'Verified',
    badgeClass: 'user-profile__avatar__badge--tier2'
  }
};
