export const initialValues = {
  email: ''
};

export const formFields = [
  {
    label: 'Email',
    name: 'email',
    type: 'text'
  }
];
