const navLinks = [
  {
    label: 'Home',
    url: '/'
  },
  {
    label: 'Buy',
    url: '/buy'
  },
  {
    label: 'Sell',
    url: '/sell'
  },
  {
    label: 'Use',
    url: '/use'
  },
  {
    label: 'History',
    url: '/history'
  }
];

export default navLinks;
